import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Card from 'react-bootstrap/Card'
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import '../styles/guide.css';

export const query = graphql`
  query($slug: String!) {
    guideDataset(SlugURL: { eq: $slug }) {
      id
      Title
      FeaturedImageURL
      Description
      DownloadURL
      SlugURL
    }
  }
`

const Guide = ({ data }) => {

    const guide = data.guideDataset

    const [ success, setSuccess ] = useState(false);
    const [ failure, setFailure] = useState(false);

    const [guideModalShow, setGuideModalShow] = useState(false);

    const [ formDatas, setFormDatas ] = useState(
      {
        guideName: null,
        guideEmail: null,
        guideNumber: 0
      }
    )

    const [ validation, setValidation ] = useState(
      {
        guideNameErr: null,
        guideEmailErr: null,
        guideNumberErr: null
      }
    )

    const handleChangeGuideName = (event) => {
      
      setValidation((prevState) => {
        return { ...prevState, guideNameErr: null };
      });

      let currentValue = event.target.value;
      setFormDatas((prevState) => {
        return { ...prevState, guideName: currentValue };
      });
    }

    const handleChangeGuideEmail = (event) => {
      setValidation((prevState) => {
        return { ...prevState, guideEmailErr: null };
      });

      let currentValue = event.target.value;
      setFormDatas( (prevState)=> {
        return { ...prevState, guideEmail: currentValue };
      });
    }

    const handleChangeGuideNumber = (event) => {
      setValidation((prevState) => {
        return { ...prevState, guideNumberErr: null };
      });

      let currentValue = event.target.value;
      setFormDatas( (prevState)=> {
        return { ...prevState, guideNumber: currentValue };
      });
    }

    const validateForm = () => {
      setSuccess(false);
      setFailure(false);

      if(!formDatas.guideName) {
        setValidation((prevState) => {
          return { ...prevState, guideNameErr: 'Please enter your name' };
        });
      } else {
        setValidation((prevState) => {
          return { ...prevState, guideNameErr: null };
        });
      }

      if(!formDatas.guideEmail || !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(formDatas.guideEmail) ) {
        setValidation((prevState) => {
          return { ...prevState, guideEmailErr: 'Please enter a valid Email' };
        });
      } else {
        setValidation((prevState) => {
          return { ...prevState, guideEmailErr: null };
        });
      }

      if(formDatas.guideNumber.length !== 10) {
        setValidation((prevState) => {
          return { ...prevState, guideNumberErr: 'Please enter a valid number' };
        });
      } else {
        setValidation((prevState) => {
          return { ...prevState, guideNumberErr: null };
        });
      }

      if(validation.guideNameErr === null && validation.guideEmailErr === null && validation.guideNumberErr === null && formDatas.guideName !== "" && formDatas.guideEmail !== "" && formDatas.guideNumber.length === 10) {
        // console.log('Post to API now!');
        document.getElementById("download").innerHTML = 'Please wait...';
        const opts = {
         "Name": formDatas.guideName,
         "EmailID": formDatas.guideEmail,
         "MobileNumber": formDatas.guideNumber,
         "PageURL":guide.SlugURL,
         "EmailTemplate": "CourseTemplate",
         "ReferenceId":guide.Id,
         "ReferenceType":"Guide",
        }

        fetch('https://gsq4d48100.execute-api.ap-south-1.amazonaws.com/dev/leads/create-lead', {
          method: 'post',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(opts)
          }).then(function(response) {
            return response.json();
          }).then(function(data) {
            if(data.success) {
              setSuccess(true);
              document.getElementById("download").innerHTML = 'Download';
              setFormDatas(
                {
                  guideName: '',
                  guideEmail: '',
                  guideNumber: 0
                }
              );
                
            } else {
              setFailure(true);
              document.getElementById("download").innerHTML = 'Download';
            }
            
          });

      }

    }

    const handleGuideModal = () => {
      setGuideModalShow (!guideModalShow)
    }

    return (
        <Layout title={guide.Title} description={guide.Description} keywords={''} image={guide.FeaturedImageURL} url={''} author={'Crampete'}>
          <hr className="mt-0 mb-4 d-none d-md-block" />
            <div class="container">
              <div className="row">
                  <Card className="border-0 mb-4">
                    <div class="row">
                      <div class="col-md-6 mb-4">
                        <Card.Img variant="top" src={guide.FeaturedImageURL} />
                      </div>
                      <div class="col-md-6 pl-4 pr-4">
                        <Card.Title style={{fontSize: "1.5rem"}}>{guide.Title}</Card.Title>
                        <div dangerouslySetInnerHTML={{ __html: guide.Description }} />
                        <Button onClick={handleGuideModal} className="btn btn-primary">Download eBook</Button>
                      </div>
                    </div>
                    <Card.Body className="pl-0" style={{display:"none"}}>
                      <Card.Text>
                      <p>Are you keen on enforcing your creativity into website creation! Then this webinar “Introduction to JavaScript” will definitely be an informative one.</p>
                      <p>Give shape to your creativity with the technical skills for a fruitful career. The experts from the industry will give clarity about this field.</p>
                      <p>Fly to greater heights by giving wings to your dreams.</p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
              </div>
            </div>
          
          <Modal className="guideModal" show={guideModalShow} onHide={handleGuideModal}>
            <Modal.Header><p>Almost there: please complete this form and click the button below to gain instant access. </p></Modal.Header>
            <Modal.Body>
              {success && <Alert variant="success" style={{fontSize: '14px', textAlign: 'center'}}>Thanks, please click <a href={guide.DownloadURL} target="_blank" rel="noopener noreferrer">here</a> to download your eBook</Alert>}
              {failure && <Alert variant="danger" style={{fontSize: '14px', textAlign: 'center'}}>Oops, something went wrong!</Alert>}
              <Form>
              <Form.Group controlId="formBasicName">
                <Form.Control type="text" name="guideName" onChange={handleChangeGuideName} value={formDatas.guideName} placeholder="Name" />
                <Form.Text className="text-danger">{validation.guideNameErr}</Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Control type="email" name="guideEmail" onChange={handleChangeGuideEmail} value={formDatas.guideEmail} placeholder="Email" />
                <Form.Text className="text-danger">{validation.guideEmailErr}</Form.Text>
              </Form.Group>
              <Form.Group controlId="formBasicNumber">
                <Form.Control type="number" name="guideNumber" onChange={handleChangeGuideNumber} value={ (formDatas.guideNumber === 0 ? '' : formDatas.guideNumber) } placeholder="Phone Number" />
                <Form.Text className="text-danger">{validation.guideNumberErr}</Form.Text>
              </Form.Group>
              <Form.Group className="text-center">
                <Button variant="primary" id="download" onClick={validateForm}>Download</Button>
              </Form.Group>

              </Form>
            </Modal.Body>
          </Modal>

        </Layout>
    )
}

export default Guide
